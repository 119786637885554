
































































import dayjs from 'dayjs';
import { Component, Vue, Watch } from 'vue-property-decorator';
import authModule from '@/store/Auth';
import wordpressModule from '@/store/Wordpress';

@Component({})
export default class TermsAndConditionsDialog extends Vue {
  loading = false;

  agree = false;

  get showDialog() {
    return this.latestTerms && (this.requireUserAgree || this.accessLocked);
  }

  get user() {
    return authModule.user;
  }

  get latestTermsDate() {
    return this.latestTerms ? dayjs(this.latestTerms.date) : null;
  }

  get isUserAgree() {
    if (!this.user || !this.latestTermsDate) {
      return false;
    }
    return (
      this.user.agreeTcAt && this.latestTermsDate.isBefore(this.user.agreeTcAt)
    );
  }

  get latestTerms() {
    return wordpressModule.latestTerms;
  }

  get requireUserAgree() {
    return this.latestTerms && !this.isUserAgree;
  }

  get isGracePeriod() {
    return wordpressModule.isGracePeriod;
  }

  get gracePeriodEnd() {
    return wordpressModule.gracePeriodEnd;
  }

  get accessLocked() {
    // access lock does not apply to owner
    if (
      !authModule.user ||
      !authModule.activeOrganisation ||
      authModule.isOwner
    ) {
      return false;
    }

    // show access lock if owner has not agreed to terms and grace period is over
    const { owner } = authModule.activeOrganisation.organisation;
    return (
      this.latestTermsDate &&
      this.gracePeriodEnd &&
      !this.isGracePeriod &&
      (!owner.agreeTcAt ||
        dayjs(owner.agreeTcAt).isBefore(this.latestTermsDate))
    );
  }

  get ownerEmail() {
    if (
      authModule.activeOrganisation &&
      authModule.activeOrganisation.organisation.owner.email
    ) {
      return authModule.activeOrganisation.organisation.owner.email;
    }
    return '';
  }

  dialogContent(post: WordpressPost) {
    if (!post) {
      return '';
    }

    let content = '';
    content = post.content.rendered;

    return content;
  }

  async agreeTermsAndConditions() {
    if (!this.user) {
      return;
    }
    this.user.agreeTcAt = dayjs().toISOString();
    await this.user.save();
  }

  @Watch('showDialog', { immediate: true })
  showDialogChanged(val: boolean) {
    if (!val) {
      this.$emit('dismiss', 'terms');
    }
  }
}
