












































import { Component, Vue } from 'vue-property-decorator';

import authModule from '@/store/Auth';

import TrainingBanner from '@/components/dashboard/TrainingBanner.vue';
import SubscriptionBanner from '@/components/dashboard/SubscriptionBanner.vue';
import SubscriptionRequiredDialog from '@/components/common/SubscriptionRequiredDialog.vue';
import UpdatingDialog from '@/components/dashboard/UpdatingDialog.vue';
import EnableTotpDialog from '@/components/auth/EnableTotpDialog.vue';
import WelcomeDialog from '@/components/auth/WelcomeDialog.vue';
import TermsAndConditionsDialog from '@/components/auth/TermsAndConditionsDialog.vue';

import { OrganisationType } from '@/api';
import TermsAndConditionsBanner from '@/components/dashboard/TermsAndConditionsBanner.vue';

@Component({
  components: {
    TrainingBanner,
    SubscriptionBanner,
    SubscriptionRequiredDialog,
    UpdatingDialog,
    EnableTotpDialog,
    WelcomeDialog,
    TermsAndConditionsDialog,
    TermsAndConditionsBanner,
  },
})
export default class DashboardView extends Vue {
  dialogs = ['terms', 'welcome', 'totp', 'subscriptionRequired', 'updating'];

  removeDialog(dialog: string) {
    this.dialogs = this.dialogs.filter(d => d !== dialog);
  }

  canShowDialog(dialog: string) {
    return this.dialogs.length && this.dialogs[0] === dialog;
  }

  get ownerAgreeTcAt() {
    if (!authModule.user || !authModule.activeOrganisation) {
      return null;
    }
    if (authModule.isOwner) {
      return authModule.user.agreeTcAt;
    }
    return authModule.activeOrganisation.organisation.owner.agreeTcAt;
  }

  get canAccess() {
    if (this.isTrainingSession) {
      return this.isActive || authModule.isManager;
    }
    return authModule.canAccessRoute;
  }

  get activeOrg() {
    return authModule.activeOrganisation;
  }

  get subscriptionSunset() {
    if (!this.activeOrg || !this.activeOrg.organisation) return false;
    return this.activeOrg.organisation.voPriceArchived;
  }

  get validSubscription() {
    return authModule.validSubscription;
  }

  get isRegular() {
    return (
      !!this.activeOrg &&
      this.activeOrg.organisation.type === OrganisationType.Regular
    );
  }

  get isTrainingSession() {
    return (
      !!this.activeOrg &&
      this.activeOrg.organisation.type === OrganisationType.TrainingSession
    );
  }

  get isActive() {
    return this.activeOrg && this.activeOrg.organisation.isActive;
  }

  get alwaysActive() {
    return (
      this.activeOrg &&
      this.activeOrg.organisation.manualVitalonlineAccess === true
    );
  }

  get subscriptionStatus() {
    return this.activeOrg && this.activeOrg.organisation.subscriptionStatus;
  }

  get showSubscriptionBanner() {
    return this.isRegular && (!this.isActive || this.subscriptionSunset);
  }

  get routeKey() {
    return authModule.dashboardRouteKey;
  }

  get updatingDialog() {
    return authModule.updatingDialog;
  }
}
